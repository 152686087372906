@import "./../../app/variables.scss";

.label {
  color: $color-teal;
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
}

.value {
  color: $color-grey-1;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
}
