@import "~app/mixins";

.tabs {
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    user-select: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    transition: color $transition-delay;
  }

  &.line {
    li {
      margin: 0 20px;
      padding-bottom: 3px;
      color: $color-grey-1;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;

      &[aria-selected=true] {
        cursor: default;
      }

      &:hover,
      &[aria-selected=true] {
        box-shadow: 0 1px 0 0 $color-grey;

        @include primary-color();
      }
    }
  }

  &.default {
    ul {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 0;
    }

    li {
      height: 50px;
      width: 100%;
      color: $color-grey;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      padding: 15px;
      border-top: 1px solid $color-grey-light;
      border-bottom: 1px solid $color-grey-light;
      border-left: 1px solid $color-grey-light;
      border-right: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      background-color: #ddd;

      &:last-child {
        border-right: 1px solid $color-grey-light;
      }

      &[aria-selected=true] {
        cursor: default;
      }

      &:hover,
      &[aria-selected=true] {
        background: $color-dirty-white;
        border-bottom: 1px solid transparent;

        @include primary-color();
      }
    }
  }
}
